import { useCallback } from 'react';
import { cn } from '@divlab/divanui';

import * as ModalWindows from '@Stores/ModalWindows';
import useRenderType from '@Hooks/useRenderType';
import GallerySection from '@Components/GallerySection';
import ReviewInCatalog from './elems/ReviewInCatalog';
import styles from './Reviews.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { PageCategoryData } from '@Pages/PageCategory/typings';
import type { IndexesData } from '@Modals/ReviewModal';

export interface ReviewsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  reviewsArea: PageCategoryData['reviewsArea'];
  //TODO: Нужно только на время тестирования разной вёрстки в разных категориях, потом можно убрать
  category: string;
}

const Reviews: FC<ReviewsProps> = (props) => {
  const { className, reviewsArea, category, ...restProps } = props;
  const { title, reviews: allReviews } = reviewsArea;
  const reviews = allReviews.filter((review) => review.product);
  const { isSSR, isCSR } = useRenderType();
  const testCase = { stellazhi: 1, kresla: 2, krovati: 3, komody: 4 }[category];
  let visibleCount = 5;

  if (testCase === 2 || testCase === 4) visibleCount = 10;

  const visibleReviews = isSSR ? reviews.slice(0, visibleCount) : reviews;

  const getReviewAndPhotoIndexes = useCallback(
    (photoId: number): IndexesData => {
      for (let reviewIndex = 0; reviewIndex < visibleReviews.length; reviewIndex += 1) {
        const review = visibleReviews[reviewIndex];
        const photoIndex = review.photos.findIndex((photo) => photo.id === photoId);

        if (photoIndex !== -1) return { reviewIndex, photoIndex };
      }

      return null;
    },
    [visibleReviews],
  );

  const handleClickReviewImage = useCallback(
    (e: MouseEvent, photoId: number) => {
      const indexes = getReviewAndPhotoIndexes(photoId);

      if (!indexes) return;

      ModalWindows.open('Review', { indexes, reviews: visibleReviews });
    },
    [visibleReviews, getReviewAndPhotoIndexes],
  );

  if (visibleReviews.length === 0) return null;

  return (
    <GallerySection
      className={cn(styles.section, className)}
      cnGallery={styles.gallery}
      cnSlide={styles.slide}
      title={title}
      slides={visibleReviews}
      defaultSizes
      renderSlide={(review) => (
        <ReviewInCatalog
          className={styles.review}
          review={review}
          onClickImage={handleClickReviewImage}
          isFullyClickable={isCSR || testCase === 1 || testCase === 2 ? true : false}
        />
      )}
      {...restProps}
    />
  );
};

export default Reviews;
