import { useState } from 'react';
import { cn, Gallery } from '@divlab/divanui';
import { IconChevronBottom } from '@divlab/divanui/icons';

import useTranslation from '@Queries/useTranslation';
import useRenderType from '@Hooks/useRenderType';
import Link from '@Navigation/Link';
import Section from '@Components/Section';
import styles from './PopularPrompts.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { PageCategoryData } from '@Pages/PageCategory/typings';

export interface PopularPromptsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  popularPromptsArea: PageCategoryData['popularPromptsArea'];
  view: 'list' | 'scroll';
}

const PopularPrompts: FC<PopularPromptsProps> = (props) => {
  const { className, popularPromptsArea, view, ...restProps } = props;
  const { title, prompts } = popularPromptsArea;
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();
  const { isCSR } = useRenderType();
  const visiblePrompts = isCSR && collapsed ? prompts.slice(0, 10) : prompts;

  const handleClick = () => {
    setCollapsed((prevState) => !prevState);
  };

  if (prompts.length === 0) return null;

  return (
    <Section {...restProps} title={<span>{title}</span>} className={cn(styles.section, className)}>
      <div className={styles.promptsWrapper}>
        {view === 'scroll' ? (
          <Gallery spaceBetween={10}>
            {prompts.map((prompt) => (
              <Link to={prompt.link} key={prompt.name}>
                <div className={styles.button}>
                  <span className={styles.promptName}>{prompt.name}</span>
                </div>
              </Link>
            ))}
          </Gallery>
        ) : (
          <div className={styles.list}>
            {visiblePrompts.map((prompt) => {
              return (
                <Link key={prompt.name} to={prompt.link}>
                  <div className={styles.button}>
                    <span className={styles.promptName}>{prompt.name}</span>
                  </div>
                </Link>
              );
            })}

            {isCSR && prompts.length > 10 && (
              <div className={styles.showAll} onClick={handleClick}>
                {collapsed ? t('ui.show-all') : t('ui.hide')}
                <IconChevronBottom
                  className={cn(styles.chevron, { [styles.active]: !collapsed })}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Section>
  );
};

export default PopularPrompts;
