import { useMedias } from '@divlab/divanui';

import GallerySection from '@Components/GallerySection';
import DefaultBanner from './templates/DefaultBanner';
import styles from './SaleBanners.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { SaleBannerData } from '@Promo/typings';

interface SaleBannersProps extends HTMLAttributes<HTMLDivElement> {
  banners: SaleBannerData[];
}

const SaleBanners: FC<SaleBannersProps> = (props) => {
  const { banners, ...restProps } = props;
  const { isDesktop } = useMedias();

  return (
    <GallerySection
      cnGallery={styles.gallery}
      cnSlide={styles.slide}
      defaultGap
      slides={banners}
      renderSlide={(banner) => <DefaultBanner banner={banner} />}
      scrollable={isDesktop}
      analytics={(banner) => ({
        id: banner.text?.title,
        listTitle: 'banners',
        card: banner,
        cardType: 'promo',
        targetPathname: banner.link,
      })}
      {...restProps}
    />
  );
};

export default SaleBanners;
